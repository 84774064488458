const WaitingForEditing = 'waiting-for-editing';
const UnderEditing = 'editing';
const EditingFinished = 'editing-finished';

export const ManualEditingStatus = {
  WAITING_FOR_EDITING: WaitingForEditing,
  UNDER_EDITING: UnderEditing,
  EDITING_FINISHED: EditingFinished,
};

const ProjectCreated = 'created';
const ProjectStarted = 'started';
const ProjectOnHold = 'on-hold';
const ProjectInProgress = 'in-progress';
const ProjectRetouching = 'retouching';
const ProjectDelivered = 'delivered';
const ProjectPostProcessing = 'post-processing';
const ProjectFinished = 'finished';
const ProjectCanceled = 'canceled';
const ProjectDeleted = 'deleted';
const ProjectUnderComplain = 'complain-in-progress';
const ProjectPhotographerEditing = 'photographer-editing';
const ProjectEditorPostProduction = 'editor-post-production';
const ProjectAiPostProduction = 'ai-post-production';

export const ProjectStatus = {
  FINISHED: ProjectFinished,
  CANCELLED: ProjectCanceled,
  DELETED: ProjectDeleted,
  CREATED: ProjectCreated,
  STARTED: ProjectStarted,
  ON_HOLD: ProjectOnHold,
  IN_PROGRESS: ProjectInProgress,
  RETOUCHING: ProjectRetouching,
  DELIVERED: ProjectDelivered,
  POST_PROCESSING: ProjectPostProcessing,
  UNDER_COMPLAIN: ProjectUnderComplain,
  PHOTOGRAPHER_EDITING: ProjectPhotographerEditing,
  EDITOR_POST_PRODUCTION: ProjectEditorPostProduction,
  AI_POST_PRODUCTION: ProjectAiPostProduction,
};

export const isProjectInTerminatedStatus = (status: string) => [
  ProjectStatus.CANCELLED,
  ProjectStatus.DELETED,
].includes(status);

export const isProjectInDeliveredStatus = (status: string) => [
  ProjectStatus.DELIVERED,
  ProjectStatus.FINISHED,
].includes(status);

export const translateProjectStatus = (status: string) => {
  if (status === ProjectStatus.STARTED) {
    return 'Waiting for shooting';
  } else if (status === ProjectStatus.RETOUCHING) {
    return 'Waiting for editing';
  } else if (status === ProjectStatus.POST_PROCESSING) {
    return 'Editing';
  } else if (status === ProjectStatus.CANCELLED) {
    return 'Canceled';
  } else {
    return 'Delivered';
  }
};
