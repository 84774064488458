import React, {useEffect, useState} from 'react';

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import {Helmet} from 'react-helmet';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  useForm,
} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import throttle from 'throttleit';

import DefaultAuth from '../../../layouts/auth/Default';
import {
  useResetPassword,
} from '../../../api/user-api';

import {RiEyeCloseLine} from 'react-icons/ri';
import {MdOutlineRemoveRedEye} from 'react-icons/md';
import Input from '../../../components/form/Input';
import illustration from '../../../assets/img/auth/auth.png';
import Bugsnag from '@bugsnag/js';

const textColorSecondary = 'gray.400';

export const NewPassword = () => {
  const params = useParams();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [formError, setFormError] = useState(null);
  const textColor = useColorModeValue('navy.700', 'white');

  const schema = z.object({
    pw: z
      .string()
      .min(8, {message: 'Password required and min 8 chars'})
      .max(50, 'Password required and min 50 chars'),
    passwordAgain: z
      .string()
      .min(8, 'Password required and min 8 chars')
      .max(50, 'Password required and min 50 chars'),
  }).refine(
    (data) => data.pw === data.passwordAgain,
    {
      message: 'Passwords don\'t match',
      path: ['passwordAgain'],
    },
  );

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm(
    {
      defaultValues: {
        pw: '',
        passwordAgain: '',
      },
      mode: 'all',
      resolver: zodResolver(schema),
    },
  );

  const [
    {
      loading: submitLoading,
      error: apiError,
    },
    resetPassword,
  ] = useResetPassword();

  const onSubmit = async (data: { passwordAgain: any }) => {
    try {
      await resetPassword({
        data: {password: data.passwordAgain, token: params.regToken},
      });
      toast({
        title: 'New password set sucessfully',
        status: 'success',
        isClosable: true,
      });
      navigate('/login');
    } catch (error) {
      Bugsnag.notify(error as Error);
      toast({
        title: 'Error setting new password',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(
    () => {
      if (apiError) {
        const errorMessage = apiError.response?.data?.error_message
          || 'We couldn\'t set your new password. Please contact to our accountants!';

        toast({
          title: errorMessage,
          status: 'error',
          isClosable: true,
        });

        setFormError(errorMessage);
      }
    },
    [apiError],
  );

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
    >
      <Helmet>
        <title>Confirming registration | Photon Editor</title>
      </Helmet>
      <Flex
        maxW={{base: '100%', md: 'max-content'}}
        w='100%'
        mx={{base: 'auto', lg: '0px'}}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{base: '30px', md: '60px'}}
        px={{base: '25px', md: '0px'}}
        mt={{base: '40px', md: '14vh'}}
        flexDirection='column'
      >
        <Flex align='center' direction='column' justify='center' textAlign='center'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Confirm Registration
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'
          >
            Provide your new password!
          </Text>
        </Flex>

        <Container maxWidth='sm' pt='40px' pb='40px'>
          <Box flexGrow={1} mt={3} textAlign='center'>
            <form
              noValidate
              onSubmit={throttle(handleSubmit(onSubmit), 500)}
            >
              <Input
                error={errors.pw}
                field='password'
                required
                type={show ? 'text' : 'password'}
                registerProps={register('pw')}
                inputStyles={{
                  fontSize: 'sm',
                  mb: '24px',
                  size: 'lg',
                  variant: 'auth',
                }}
                rightAddon={
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{cursor: 'pointer'}}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={() => setShow(!show)}
                    />
                  </InputRightElement>
                }
              >
                Password
              </Input>

              <Input
                error={errors.passwordAgain}
                field='passwordAgain'
                required
                type={show ? 'text' : 'password'}
                registerProps={register('passwordAgain')}
                inputStyles={{
                  fontSize: 'sm',
                  mb: '24px',
                  size: 'lg',
                  variant: 'auth',
                }}
                rightAddon={
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{cursor: 'pointer'}}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={() => setShow(!show)}
                    />
                  </InputRightElement>
                }
              >
                Password Again
              </Input>

              {
                formError
                && <Text color='red.500' mt={1}>{formError}</Text>
              }

              <Box mt={2}>
                <Button
                  type='submit'
                  fontSize='sm'
                  variant='photonDark'
                  fontWeight='500'
                  w='100%'
                  h='50'
                  mb='24px'
                  isLoading={submitLoading}
                  loadingText='Please wait...'
                >
                  Set password
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Flex>
    </DefaultAuth>
  );
};
