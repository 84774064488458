import React, {useCallback, useEffect} from 'react';
import {
  Outlet,
  useLocation,
} from 'react-router-dom';
import {
  LAST_SEEN,
  useLocalStorage,
} from '../hooks/useLocalStorage';
import moment from 'moment';

export const ProtectedLayout = () => {
  const location = useLocation();
  const [lastSeen, setLastSeen] = useLocalStorage(LAST_SEEN, Date.now());

  const refreshCredentials = useCallback(
    () => {
      if (document.visibilityState === 'visible') {
        if (moment().diff(moment(lastSeen), 'h', true) > 3) {
          setLastSeen(Date.now());
          window.location.reload();
        } else {
          setLastSeen(Date.now());
        }
      }
    },
    [lastSeen, setLastSeen],
  );

  useEffect(
    refreshCredentials,
    [location],
  );

  useEffect(
    () => {
      document.removeEventListener('visibilitychange', refreshCredentials);
      document.addEventListener('visibilitychange', refreshCredentials);
    },
    [],
  );

  return <Outlet />;
};
