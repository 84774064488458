import axios from 'axios';

import config from '../config';
import {
  getItem,
  PREV_PATH,
  removeItem,
  setItem,
  USER,
} from '../hooks/useLocalStorage';

export default (url?: string) => {
  const customAxios = axios.create(
    {baseURL: url || `${config.apiUrl}/editor`},
  );

  customAxios.interceptors.request.use(
    (config) => {
      const userCode = getItem(USER);
      const user = userCode ? JSON.parse(userCode) : null;

      if (user && user.accessToken) {
        config.headers.authorization = `Bearer ${user.accessToken}`;
      }
      return config;
    },
    async (error) => await Promise.reject(error),
  );

  customAxios.interceptors.response.use(
    (response) => response,
    async (err) => {
      if (err.response?.status === 401) {
        removeItem(USER);
        setItem(PREV_PATH, JSON.stringify(window.location.pathname));
        window.location.assign('/login');
      }

      return await Promise.reject(err);
    },
  );

  return customAxios;
};
