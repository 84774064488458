import React, {MouseEvent, useCallback, useEffect, useMemo} from 'react';

import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {BsHourglassSplit} from 'react-icons/bs';
import {FaFlagCheckered} from 'react-icons/fa';
import {BiSolidDownArrowAlt, BiSolidUpArrowAlt} from 'react-icons/bi';
import {
  GrLinkNext,
  GrLinkPrevious,
} from 'react-icons/gr';

import moment from 'moment';

import useLocalStorage from 'react-use-localstorage';

import {NavLink} from 'react-router-dom';

import {useApplyEditing} from 'api/projects-api';


export interface Row {
  original: any
}

function ApplyButton(
  props: {
    projectId: string
    value: string
    reloadData: () => any
  },
) {
  const [
    {
      loading: applyEditingInProgress,
      error: applyEditingError,
    },
    applyEditing,
  ] = useApplyEditing(props.projectId);

  const {
    isOpen: isApplyModalOpen,
    onOpen: onApplyModalOpen,
    onClose: onApplyModalClose,
  } = useDisclosure();
  const toast = useToast();

  useEffect(
    () => {
      if (applyEditingError) {
        toast({
          title: applyEditingError.response?.data?.error_message || 'Error while applying for editing',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [applyEditingError],
  );

  return <>
    <Button
      variant='photonDark'
      me='10px' w='20px'
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }

        onApplyModalOpen();
      }}
      fontSize='sm'
      isLoading={applyEditingInProgress}
      fontWeight='700'
    >
      {props.value}
    </Button>

    <Modal isOpen={isApplyModalOpen} onClose={onApplyModalClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Apply for editing</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text>Do you want to apply for this project?</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            variant='photonMedium'
            mr={3}
            onClick={onApplyModalClose}
          >
            Cancel
          </Button>
          <Button
            variant='photonDark'
            onClick={
              (e: MouseEvent<HTMLButtonElement>) => {
                if (e) {
                  e.stopPropagation();
                  e.preventDefault();
                }

                const call = async () => {
                  try {
                    await applyEditing();
                    onApplyModalClose();
                    await props.reloadData();
                  } catch (error) {
                    console.error('Error during apply:', error);
                  }
                };

                call();
              }
            }
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>;
}

export default function ProjectTable(
  props: {
    columnsData: any
    tableData: any
    total: number
    reloadData: () => any
  },
) {
  const {columnsData, tableData, total} = props;

  const [savedPageSize, savePageSize] = useLocalStorage('pageSize', '10');
  const [savedPageIndex, savePageIndex] = useLocalStorage('pageIndex', '0');

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const rowNo = Number(savedPageSize);
  const pageIdx = Number(savedPageIndex);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: rowNo,
        pageIndex: pageIdx,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state: {pageIndex, pageSize},
  } = tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  // const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  const pageSizes = [10, 25, 50];
  const currentPageSize = pageSize;
  const startRow = pageIndex * currentPageSize + 1;
  const endRow = Math.min((pageIndex + 1) * currentPageSize, total);

  const handlePageChange = useCallback(
    (pageIdx: number) => {
      savePageIndex(`${pageIdx}`);
      gotoPage(pageIdx);
    },
    [gotoPage, savePageIndex],
  );

  return <Box bg='white' borderRadius='20px' w='100%' px='0px' overflowX='scroll'>
    <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' minW={{base: '1000px', md: 'auto'}}>
      <Thead>
        {
          headerGroups.map(
            (headerGroup, index) => <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='0px'
                  key={index}
                  maxW='20px'
                  textAlign='center'
                >
                  <Flex
                    justify='start'
                    align='center'
                    fontSize='9px'
                    color='gray.400'
                  >
                    {column.render('Header')}
                    {
                      column.isSorted
                        ? (
                          column.isSortedDesc
                            ? <Flex direction='column'>
                              <BiSolidUpArrowAlt opacity={0.1} size='20px'/>
                              <BiSolidDownArrowAlt size='20px' color='#6B52FFB3'/>
                            </Flex>
                            : <Flex direction='column'>
                              <BiSolidUpArrowAlt size='20px' color='#6B52FFB3'/>
                              <BiSolidDownArrowAlt opacity={0.1} size='20px'/>
                            </Flex>
                        ) : <Flex opacity={0.5} direction='column'>
                          <BiSolidUpArrowAlt size='20px'/>
                          <BiSolidDownArrowAlt size='20px'/>
                        </Flex>
                    }
                  </Flex>
                </Th>
              ))}
            </Tr>,
          )
        }
      </Thead>

      <Tbody {...getTableBodyProps()}>
        {
          page.map(
            (row, index) => {
              prepareRow(row);

              return (
                <Tr {...row.getRowProps()} key={index}>
                  {
                    row.cells.map(
                      (cell, index) => {
                        const currentRow: Row = cell.row;

                        let data;

                        if (cell.column.Header === 'Action') {
                          if (cell.value === 'Apply') {
                            data = (
                              <Flex align='center'>
                                <ApplyButton
                                  projectId={currentRow.original.id}
                                  value={cell.value}
                                  reloadData={props.reloadData}
                                />
                              </Flex>
                            );
                          } else {
                            data = (
                              <Flex align='center'>
                                <Link as={NavLink} to={`/intel/project/${currentRow.original.id}`}>
                                  <Button
                                    variant={cell.value === 'Edit' ? 'photonLight' : 'photonMedium'}
                                    me='10px'
                                    w='20px'
                                    fontSize='12px'
                                    fontWeight='700'
                                  >
                                    {cell.value}
                                  </Button>
                                </Link>
                              </Flex>
                            );
                          }
                        } else if (cell.column.Header === 'Status') {
                          let tooltipText: string;

                          if (currentRow.original.status === 'post-processing') {
                            tooltipText = 'Your turn, upload edited files ASAP';
                          } else {
                            tooltipText = 'Wait until photographer uploads the photos';
                          }

                          data = (
                            <Tooltip
                              hasArrow
                              label={tooltipText}
                            >
                              <Flex align='center'>
                                {currentRow.original.status === 'post-processing'
                                  ? <FaFlagCheckered color='black' size='22px'/>
                                  : <BsHourglassSplit color='gray' size='22px'/>}
                              </Flex>
                            </Tooltip>
                          );
                        } else if (cell.column.Header === 'Delivery deadline' || cell.column.Header === 'Photographer\'s deadline') {
                          data = (
                            <Flex align='center'>
                              <Text me='10px' color={textColor} fontSize='12px' fontWeight='700'>
                                {moment(cell.value).format('YYYY.MM.DD.  HH:mm')}
                              </Text>
                            </Flex>
                          );
                        } else {
                          data = (
                            <Text color={textColor} fontSize='12px' fontWeight='700'>
                              {cell.value}
                            </Text>
                          );
                        }

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{sm: '14px'}}
                            borderColor='transparent'
                            textAlign='center'
                            maxW='20px'
                          >
                            {data}
                          </Td>
                        );
                      },
                    )
                  }
                </Tr>
              );
            },
          )
        }
      </Tbody>
    </Table>
    <Flex justify='space-between' m='10px' px='10px'>
      <Flex>
        <Select
          value={currentPageSize}
          onChange={
            (e) => {
              setPageSize(Number(e.target.value));
              savePageSize(e.target.value);
              handlePageChange(0);
            }
          }
          variant='photonMedium'
          w='70px'
        >
          {
            pageSizes.map(
              (size) => <option key={size} value={size}>
                {size}
              </option>,
            )
          }
        </Select>
      </Flex>
      <Flex>
        <Button
          onClick={
            () => {
              handlePageChange(pageIndex - 1);
              tableInstance.previousPage();
            }
          }
          bg='white'
          disabled={!canPreviousPage}
        >
          <GrLinkPrevious/>
        </Button>
        <Flex justify='center' mx='10px' mt='6px'>
          <Text color='gray.400'>
            {startRow} - {endRow} of {total}
          </Text>
        </Flex>
        <Button
          onClick={
            () => {
              tableInstance.nextPage();
              handlePageChange(pageIndex + 1);
            }
          }
          bg='white'
          disabled={!canNextPage}
        >
          <GrLinkNext/>
        </Button>
      </Flex>
    </Flex>

  </Box>;
}
