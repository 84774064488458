import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import packageInfo from '../../package.json';
import config from '../config';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';


Bugsnag.start({
  apiKey: '6c58281178e88aee61b2302caa1d8560',
  plugins: [new BugsnagPluginReact()],
  appVersion: packageInfo.version,
  releaseStage: config.environment,
  enabledReleaseStages: ['production', 'staging', 'testing'],
});
BugsnagPerformance.start({apiKey: '6c58281178e88aee61b2302caa1d8560'});

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
