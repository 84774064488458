import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import config from 'config';

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white');
  const linkColor = useColorModeValue({base: 'gray.400', lg: 'white'}, 'white');

  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent='space-between'
      px={{base: '30px', md: '0px'}}
      pb='30px'
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{base: '20px', lg: '0px'}}
      >
        {' '}
        &copy;
        <Text as='span' fontWeight='500' ms='4px'>
          <Link mx='3px' color={textColor} href='https://photon.rocks' target='_blank' fontWeight='700' rel='noreferrer'>
            Photon Technologies Ltd. 2018-{new Date().getFullYear()}.
          </Link>
          - All Rights Reserved.
        </Text>
      </Text>
      <List display='flex'>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight='500' color={linkColor} href='mailto:hello@thephoton.hu'>
            Support
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight='500' color={linkColor} href={`${config.appBaseUrl}/about`}>
            About Us
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight='500' color={linkColor} href={`${config.appBaseUrl}/terms-and-conditions`}>
            Terms of Use
          </Link>
        </ListItem>
        <ListItem>
          <Link fontWeight='500' color={linkColor} href={`${config.appBaseUrl}/blog`}>
            Blog
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
