export const buttonStyles = {
  components: {
    Input: {
      variants: {
        photonInput: {
          backGroundColor: 'red',
        },
      },
    },
    Button: {
      variants: {
        photonDark: {
          bg: 'photonDeepBlue.100',
          borderRadius: '25px',
          color: 'photonOffWhite.100',
          fontSize: '16px',
          fontWeight: '500',
          height: '40px',
          letterSpacing: '0.02em',
          lineHeight: '24px',
          width: '100px',
          px: '32px',
          py: '12px',
          _disabled: {
            backgroundColor: 'photonDeepBlue.70',
            opacity: 0.7,
          },
          _hover: {
            boxShadow: '0 0 10px #a6a4ff',
            textDecoration: 'none',
            _disabled: {
              backgroundColor: 'photonDeepBlue.70',
            },
          },
        },
        photonDarkYellow: {
          bg: 'photonDarkYellow.100',
          borderRadius: '25px',
          color: 'photonOffWhite.100',
          fontSize: '16px',
          fontWeight: '500',
          height: '40px',
          letterSpacing: '0.02em',
          lineHeight: '24px',
          width: '100px',
          px: '32px',
          py: '12px',
          _disabled: {
            backgroundColor: 'photonDeepBlue.70',
            opacity: 0.7,
          },
          _hover: {
            boxShadow: '0 0 10px #a6a4ff',
            textDecoration: 'none',
            _disabled: {
              backgroundColor: 'photonDeepBlue.70',
            },
          },
        },
        photonMedium: {
          bg: 'photonOffWhite.100',
          borderRadius: '25px',
          color: 'photonDeepBlue.100',
          borderColor: 'photonDeepBlue.100',
          borderWidth: '3px',
          fontSize: '16px',
          fontWeight: '500',
          height: '40px',
          letterSpacing: '0.02em',
          lineHeight: '24px',
          width: '100px',
          px: '32px',
          py: '12px',
          _disabled: {
            backgroundColor: 'photonDeepBlue.70',
            opacity: 0.7,
          },
          _hover: {
            boxShadow: '0 0 10px #a6a4ff',
            textDecoration: 'none',
            _disabled: {
              backgroundColor: 'photonDeepBlue.70',
            },
          },
        },
        photonLight: {
          bg: 'photonNeonPurple.100',
          borderRadius: '25px',
          color: 'photonOffWhite.100',
          fontSize: '16px',
          fontWeight: '500',
          height: '40px',
          letterSpacing: '0.02em',
          lineHeight: '24px',
          width: '100px',
          px: '32px',
          py: '12px',
          _disabled: {
            backgroundColor: 'photonNeonPurple.70',
            opacity: 0.5,
          },
          _hover: {
            boxShadow: '0 0 10px #a6a4ff',
            textDecoration: 'none',
            _disabled: {
              backgroundColor: 'photonNeonPurple.70',
            },
          },
        },
        photonFormDark: {
          bg: 'photonDeepBlue.70',
          borderRadius: '30px',
          color: 'white',
          fontSize: '20px',
          fontWeight: '500',
          height: '48px',
          lineHeight: '24px',
          minWidth: '140px',
          px: '38px',
          py: '14px',
        },
        photonFormLight: {
          bg: '#d2d2d2',
          borderRadius: '30px',
          color: 'black',
          fontSize: '20px',
          fontWeight: '500',
          height: '48px',
          lineHeight: '24px',
          minWidth: '140px',
          px: '38px',
          py: '14px',
          _hover: {
            _disabled: {
              backgroundColor: '#d2d2d2',
            },
          },
        },
        photonMobileLavender: {
          bg: 'photonLavender.100',
          borderRadius: '25px',
          color: 'photonOffWhite.100',
          fontSize: '16px',
          fontWeight: '500',
          height: '40px',
          letterSpacing: '0.02em',
          lineHeight: '19px',
          px: '20px',
          py: '10.5px',
          _disabled: {
            backgroundColor: 'photonNeonPurple.70',
            opacity: 0.5,
          },
          _hover: {
            boxShadow: '0 0 10px #a6a4ff',
            textDecoration: 'none',
            _disabled: {
              backgroundColor: 'photonNeonPurple.70',
            },
          },
        },
        photonBasic: {
          bg: 'none',
          color: 'black',
          fontSize: '20px',
          fontWeight: '400',
          lineHeight: '24px',
        },
        photonGalleryMore: {
          bg: 'none',
          color: 'photonDeepBlue.100',
          border: '1px solid',
          borderRadius: '25px',
          borderColor: 'photonDeepBlue.100',
          px: '20px',
          py: '8px',
        },
      },
    },
    Heading: {
      variants: {
        photonHeroHeading: {
          color: 'photonOffWhite.100',
          fontSize: ['40px', '40px', '56px'],
          fontWeight: '800',
          mb: {
            base: '25px',
            md: '32px',
          },
          lineHeight: {base: '48px', md: '70px'},
        },
        photonServicesHeroHeading: {
          color: 'black',
          fontSize: ['30px', '30px', '42px'],
          fontWeight: '800',
          mb: {
            base: '25px',
            md: '32px',
          },
          lineHeight: {base: '36px', md: '50.4px'},
        },
        photonCreatorsHeroHeading: {
          color: 'photonDeepBlue.100',
          fontSize: ['32px', '32px', '56px'],
          fontWeight: {base: '700', md: '800'},
          mb: {
            base: '24px',
            md: '20px',
          },
          lineHeight: {base: '38.4px', md: '60px'},
          wordWrap: 'normal',
        },
        photonContactHeroHeading: {
          color: 'photonNeonPurple.100',
          fontSize: ['30px', '30px', '42px'],
          fontWeight: {base: '700', md: '800'},
          lineHeight: {base: '36px', md: '50.4px'},
        },
        photonHeading: {
          color: 'photonOffWhite.100',
          fontSize: ['32px', '32px', '40px'],
          fontWeight: {base: '500', md: '600'},
          lineHeight: {base: '38.4px', md: '50px'},
          px: {
            base: 4,
            sm: 6,
            md: 12,
          },
          textAlign: 'center',
        },
        simpleHeading: {
          color: 'black',
          fontSize: '40px',
          fontWeight: '600',
          lineHeight: '48px',
          mb: {base: '39px'},
        },
        simpleSubHeading: {
          color: 'black',
          fontSize: '32px',
          fontWeight: '400',
          lineHeight: '48px',
          mb: '20px',
        },
        successHeading: {
          color: 'photonDeepBlue.100',
          fontSize: {base: '30px', md: '40px'},
          fontWeight: '600',
          lineHeight: {base: '36px', md: '48px'},
          py: {base: 0, md: '4px'},
        },
      },
    },
    Text: {
      variants: {
        photonSuccessParagraph: {
          color: 'photonDeepBlue.100',
          fontSize: {base: '24px', md: '32px'},
          fontWeight: '400',
          lineHeight: {base: '28.8px', md: '38.4px'},
        },
        photonSuccessText: {
          color: 'photonDeepBlue.100',
          fontSize: '16px',
          fontWeight: '300',
          lineHeight: '22.4px',
        },
        photonSuccessNumber: {
          color: 'photonNeonPurple.100',
          fontSize: {base: '40px', md: '50px'},
          fontWeight: '400',
          lineHeight: {base: '48px', md: '60px'},
        },
        photonSuccessNumberSub: {
          color: 'photonNeonPurple.100',
          fontSize: {base: '24px', md: '32px'},
          fontWeight: '300',
          lineHeight: {base: '28.8px', md: '38.4px'},
        },
      },
    },
    Card: {
      variants: {
        photonMdLandscape: {
          container: {
            borderRadius: '20px',
            boxShadow: '0px 0px 20px 2px rgba(32, 23, 85, 0.25)',
            h: '215px',
            overflow: 'hidden',
            w: '325px',
          },
        },
        photonMdPortrait: {
          container: {
            h: '325px',

            w: '215px',
          },
        },
        photonLgLandscape: {
          container: {
            h: '256px',

            w: '400px',
          },
        },
        photonLgPortrait: {
          container: {
            h: '400px',

            w: '256px',
          },
        },
      },
      baseStyle: {
        container: {
          boxShadow: '0px 0px 20px 2px rgba(32, 23, 85, 0.25)',
          overflow: 'hidden',
          borderRadius: '20px',
        },
      },
    },
    Tag: {
      variants: {
        photonTransparent: {
          container: {
            bg: 'transparent',
            boderColor: 'photonLavender.100',
            border: '1px solid',
            color: 'photonLavender.100',
          },
        },
        photonPurple: {
          container: {
            bg: 'photonNeonPurple.100',
            color: 'white',
          },
        },
        photonDarkYellow: {
          container: {
            bg: 'photonDarkYellow.100',
            color: 'photonDeepBlue.100',
          },
        },
        photonLightYellow: {
          container: {
            bg: 'photonLightYellow.100',
            color: 'photonDeepBlue.100',
          },
        },
      },
      baseStyle: {
        container: {
          borderRadius: '25px',
          // fontFamily: 'poppins',
          fontSize: '18px',
          fontWeight: '400',
          h: '40px',
          lineHeight: '24px',
          px: '20px',
          py: '8px',
        },
      },
    },
    Stepper: {
      variants: {
        photonStepper: {
          step: {
            alignSelf: 'flex-start',
          },
          title: {
            'color': 'photonLavender.100',
            'fontSize': '14px',
            'fontWeight': '500',
            'lineHeight': '17px',
            'textAlign': 'center',
            '&[data-status="active"]': {
              color: 'photonNeonPurple.100',
            },
          },
          indicator: {
            'border': 'none',
            'height': '44px',
            'width': '44px',
            '&[data-status="incomplete"]': {
              bg: 'photonLavender.100',
            },
            '&[data-status="active"]': {
              bg: 'photonNeonPurple.100',
            },
            '&[data-status="complete"]': {
              bg: 'photonLightLavender.100',
            },
          },
          icon: {
            height: '34px',
            width: '34px',
            color: 'photonLavender.100',
          },
        },
      },
    },
    Progress: {
      variants: {
        photonProgress: {
          track: {
            bg: 'photonLavender.100',
            height: '3px',
            left: {base: '40px', sm: '12.5%'},
            minW: '270px',
            position: 'absolute',
            top: {base: '61px', md: '81px'},
            width: '75%',
          },
          filledTrack: {
            bg: 'photonLightLavender.100',
          },
        },
      },
    },
    Form: {
      variants: {
        photonForm: {
          container: {
            label: {
              fontSize: {base: '14px', sm: '18px'},
              mb: '20px',
            },
            input: {
              bg: 'white',
              border: 'none',
              borderRadius: '30px',
              boxShadow: 'none',
              fontSize: {base: '14px', sm: '18px'},
              minH: '66px',
              lineHeight: {base: '19px', sm: '24px'},
              maxW: '100%',
              mb: {base: '20px', sm: '12px'},
              px: '34px',
              py: '18px',
              _focus: {
                borderColor: '#6b52ff',
                boxShadow: '0 0 0 1px #6b52ff',
              },
            },
          },
        },
        photonFormWithLeftAddon: {
          container: {
            label: {
              fontSize: {base: '14px', sm: '18px'},
              mb: '20px',
            },
            input: {
              bg: 'white',
              border: 'none',
              borderRadius: '30px',
              boxShadow: 'none',
              fontSize: {base: '14px', sm: '18px'},
              h: '66px',
              lineHeight: {base: '19px', sm: '24px'},
              maxW: '100%',
              mb: {base: '20px', sm: '12px'},
              pr: '34px',
              py: '18px',
              _focus: {
                borderColor: '#6b52ff',
                boxShadow: '0 0 0 1px #6b52ff',
              },
            },
          },
        },
      },
    },
    FormError: {
      variants: {
        photonFormError: {
          text: {
            mb: '20px',
            mt: '-4px',
          },
        },
      },
    },
    Checkbox: {
      variants: {
        photonCheckbox: {
          label: {
            fontSize: {base: '14px', sm: '18px'},
            lineHeight: {base: '19px', sm: '24px'},
          },
        },
      },
    },
    Textarea: {
      variants: {
        photonTextarea: {
          bg: 'white',
          border: 'none',
          borderRadius: '30px',
          boxShadow: 'none',
          fontSize: {base: '14px', sm: '18px'},
          h: '90px',
          lineHeight: {base: '19px', sm: '24px'},
          maxW: '100%',
          mb: {base: '20px', sm: '12px'},
          minH: '90px',
          minW: '100%',
          px: '34px',
          py: '18px',
          w: '100%',
          _focus: {
            borderColor: '#6b52ff',
            boxShadow: '0 0 0 1px #6b52ff',
          },
        },
      },
    },
  },
};
