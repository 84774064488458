import React from 'react';

import {Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from '@chakra-ui/react';

import useLocalStorage from 'react-use-localstorage';

import ClosedProjects from './components/closedProjects';
import OngoingProjects from './components/ongoingProjects';


const ProjectsPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useLocalStorage('tabIndex', '0');

  const tabIdx = Number(tabIndex);

  return <>
    <Text as='b' ml='20px' fontSize='3xl'>
      Projects
    </Text>
    <Box pos='relative' mt='20px'>
      <Tabs index={tabIdx} onChange={(idx) => setTabIndex(`${idx}`)}>
        <TabList ml='20px' mr='20px'>
          <Tab
            border='2px solid'
            borderColor='transparent'
            borderTopRadius='lg'
            borderBottom='none'
            bg='#a6a3ff'
            color='white'
            _selected={{bg: '#6b52ff', color: 'white'}}
            mr='5px'
          >
            Ongoing projects
          </Tab>
          <Tab
            border='2px solid'
            borderColor='transparent'
            borderTopRadius='lg'
            borderBottom='none'
            bg='#a6a3ff'
            color='white'
            _selected={{bg: '#6b52ff', color: 'white'}}
          >
            Closed projects
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <OngoingProjects/>
          </TabPanel>
          <TabPanel>
            <ClosedProjects/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  </>;
};

export default ProjectsPage;
