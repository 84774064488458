import {
  MouseEventHandler,
  useCallback,
  useEffect,
} from 'react';

import {FaSearch} from 'react-icons/fa';
import {TiDeleteOutline} from 'react-icons/ti';
import useLocalStorage from 'react-use-localstorage';
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
} from '@chakra-ui/react';

import {useGetClosedProjects} from '../../../api/projects-api';
import ProjectTable from '../../../components/ProjectTable';
import {columnsDataClosedProjects} from '../variables/columnsData';


export default function ClosedProjects() {
  const [searchField, setSearchField] = useLocalStorage('projectSearch');
  const [
    {
      data: projectsData,
      loading: getProjectsInProgress,
      error: getProjectsError,
    },
    getProjects,
  ] = useGetClosedProjects();

  const reloadProjects = useCallback(
    async (reset?: boolean) => {
      try {
        await getProjects({params: searchField && !reset ? {search: searchField} : undefined});
      } catch (error) {
        console.error('Error during reload:', error);
      }
    },
    [searchField, getProjects],
  );

  const handleSearchClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    reloadProjects();
  };

  const handleResetInput: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setSearchField('');
    reloadProjects(true);
  };

  useEffect(
    () => {
      reloadProjects();
    },
    [],
  );

  return <>
    {/* loading data */}
    {getProjectsInProgress && <Spinner />}

    {/* error during loading data */}
    {getProjectsError && <p>Error fetching data</p>}

    <Flex pos='absolute' right='50px' top={-5}>
      <Flex direction='column' mb={4} mr='10px'>
        <InputGroup>
          {searchField && <InputLeftElement>
            <Button size='20' onClick={handleResetInput}>
              <TiDeleteOutline size='20' />
            </Button>
          </InputLeftElement>}
          <Input
            variant='tableSearch'
            placeholder='Search by project name'
            onChange={(e) => setSearchField(e.target.value)}
            value={searchField}
            w='250px'
          />
        </InputGroup>
      </Flex>
      <Button onClick={handleSearchClick} bg='white' borderRadius='20px'>
        <FaSearch color='black' />
      </Button>
    </Flex>

    {
      !getProjectsInProgress
      && projectsData
      && projectsData.length > 0
      && <ProjectTable
        columnsData={columnsDataClosedProjects}
        tableData={projectsData}
        total={projectsData.length}
        reloadData={getProjects}
      />
    }

    {/* no data */}
    {
      !getProjectsInProgress
      && projectsData
      && projectsData.length === 0
      && <p>No data</p>
    }
  </>;
}
