import config, {DEV_OFFLINE_ENV} from '../../config';


export const initMocking = async () => {
  if (config.environment !== DEV_OFFLINE_ENV) {
    return;
  }

  const {worker} = await import('./msw');

  return worker.start();
};
