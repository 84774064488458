import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import {styled} from '@chakra-ui/react';

import {datePickerStyles} from "./styles";


export const PhotonDateTimePicker = styled(DateTimePicker, datePickerStyles);
