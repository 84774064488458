import {
  Box,
  Flex,
  HStack,
  Icon,
  Progress,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import {
  MdFileUpload,
  MdFileUploadOff,
  MdUpload,
} from 'react-icons/md';

import Card from 'components/card/Card';
import Dropzone from 'views/admin/profile/components/Dropzone';
import {
  useEffect,
  useState,
} from 'react';
import {uploadPhoto} from '../../../../api/projects-api';
import {CanceledError} from 'axios';


export interface UploadContentProps {
  active?: boolean;
};

const UploadContent: React.FC<UploadContentProps> = (props: { active?: boolean }) => {
  const brandColor = useColorModeValue('brand.500', 'white');

  return (
    <Box h='fit-content'>
      <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
      <Flex justify='center' mx='auto' mb='12px'>
        <Text fontSize='xl' fontWeight='700' color={brandColor} whiteSpace='break-spaces'>
          {props.active ? 'Drop the files here ...' : 'Drag \'n\' drop some files here, or click to select files'}
        </Text>
      </Flex>
      <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
        PNG and JPG files are allowed
      </Text>
    </Box>
  );
};

function FileUploadProcess(props: { file: File, projectId: string, finishing: Function, start: boolean }) {
  const [progress, setProgress] = useState(0);
  const [finalError, setFinalError] = useState(false);
  const toast = useToast();

  const makeUploadPhotoPromise = () => {
    // const delay = props.initDelay || 0;
    const [
      uploadPromise,
      cancelSource,
    ] = uploadPhoto(props.file, props.projectId, setProgress); // , delay);

    uploadPromise.then(
      () => props.finishing(),
    ).catch(
      (error) => {
        if (error instanceof CanceledError) {
          return;
        }
        setFinalError(true);
        console.log(error);
        toast({
          title: 'Picture upload error',
          description: 'An error occurred while uploading the image',
          status: 'error',
          duration: 3000,
        });
      },
    );

    return cancelSource;
  };

  useEffect(
    () => {
      if (props.start) {
        const cancelSource = makeUploadPhotoPromise();

        return () => cancelSource?.cancel();
      }
    },
    [props.start],
  );

  return (
    <Box h='fit-content' w='30%' margin='5px 10px' padding='5px' border='1px solid lightgrey' borderRadius='5px'>
      <Text fontSize='sm' fontWeight='500' color='secondaryGray.500' whiteSpace='break-spaces'>
        {props.file.name}
      </Text>
      <HStack w='100%'>
        <Progress w='100%' value={progress} />
        {
          finalError
          && <Icon as={MdFileUploadOff} w='20px' h='20px' color='red.500' />
        }
        {
          !finalError
        && progress === 100
          && <Icon as={MdFileUpload} w='20px' h='20px' color='green.500' />
        }
        {
          !finalError
        && progress < 100
        && progress > 0
          && <Text color='gray.400' fontSize='md'>{progress}</Text>
        }
        {
          !finalError
        && progress === 0
          && <Spinner size='sm' color='gray.400' />
        }
      </HStack>
    </Box>
  );
}

export default function Upload(props: { projectId: string, finishinganimage: Function, [x: string]: any }) {
  const {projectId, ...rest} = props;

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';

  const [images, setImages] = useState<Array<{ i: File, finished: boolean, loading: boolean }>>([]);

  const updateImages = (idx: any) => {
    images[idx].loading = false;
    images[idx].finished = true;

    const toStart = images.find(
      (image: any) => !image.loading && !image.finished,
    );

    if (toStart) {
      toStart.loading = true;
    }

    setImages([...images]);
  };

  return (
    <Card {...rest} m='20px' alignItems='center' p='20px' w='100%'>
      <Flex
        h='100%'
        w='100%'
        direction={{'base': 'column', '2xl': 'row'}}
        alignItems='center'
        justify='space-around'
      >
        <Dropzone
          maxW='300px'
          maxH={{'base': '60%', 'lg': '50%', '2xl': '100%'}}
          minH={{'base': '60%', 'lg': '50%', '2xl': '100%'}}
          Content={UploadContent}
          upload={
            (images: File[]) => setImages(
              images.map(
                (i, idx) => ({i, finished: false, loading: idx < 5}),
              ),
            )
          }
        />
        <Flex
          direction='column'
          w='100%'
          alignItems='center'
        >
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            w='fit-content'
            whiteSpace='nowrap'
            mt={{'base': '20px', '2xl': '50px'}}
          >
            Selected files
          </Text>

          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{'base': 'auto', '2xl': '10px'}}
            mx='auto'
            textAlign='start'
          >
            File list
          </Text>

          <Flex justify='center' direction='row' w='100%' flexFlow='wrap' maxH='300px' overflowY='auto'>
            {
              images.map(
                (image, index) => <FileUploadProcess
                  key={index}
                  file={image.i}
                  projectId={projectId}
                  finishing={() => {
                    updateImages(index);
                    props.finishinganimage();
                  }}
                  start={image.loading && !image.finished}
                />,
              )
            }
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
