import {Icon} from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdList,
} from 'react-icons/md';

const routes = [
  {
    name: 'Projects',
    layout: '/projects',
    icon: <Icon as={MdList} width='30px' height='30px' color='inherit' />,
    path: '/intel/projects',
  },
  {
    name: 'Billing',
    layout: '/billing',
    icon: <Icon as={MdBarChart} width='30px' height='30px' color='inherit' />,
    path: '/intel/billing',
  },
  {
    name: 'Profile',
    layout: '/billing',
    icon: <Icon as={MdPerson} width='30px' height='30px' color='inherit' />,
    path: '/intel/profile-settings',
  },
];

export default routes;
