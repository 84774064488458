import {
  Button,
  Checkbox,
  Link,
  Flex,
  useToast,
  Text,
} from '@chakra-ui/react';
import {useUpdateTermsAndConditions} from 'api/user-api';
import config from 'config';
import {useAuth} from 'hooks/useAuth';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

export const PolicyAndConditions = () => {
  const {login, logout, user} = useAuth();
  const [privacyNotice, setPrivacyNotice] = useState(user?.privacyNotice);
  const [acceptsTermsOfUse, setAcceptsTermsOfUse] = useState(user?.acceptsTermsOfUse);
  const toast = useToast();
  const [{error}, updateUser] = useUpdateTermsAndConditions();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error accepting terms and conditions',
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  const handleOnClose = () => {
    logout();
    navigate('/login');
  };

  const handleSave = async () => {
    try {
      await updateUser({data: {acceptsTermsOfUse, privacyNotice, userId: user.userId}});
      login({...user, acceptsTermsOfUse, privacyNotice});
      navigate('/intel/projects');
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  return (
    <Flex justifyContent='center' alignItems='center' h='100vh'>
      <Flex direction='column' justifyContent='space-around' w='550px' h='300px'>
        <Text align='center' fontWeight='bold' fontSize='20px'>
          To continue, you must accept the Privacy Notice and the Terms & Conditions.
        </Text>
        <Flex ml='150px' direction='column'>
          <Checkbox
            isChecked={privacyNotice}
            onChange={(e) => setPrivacyNotice(e.target.checked)}
            mb='20px'
          >
            I accept the{' '}
            <Link variant='photonLavender' isExternal href={`${config.appBaseUrl}/privacy-notice`}>
              Privacy Notice
            </Link>
            <sup>*</sup>
          </Checkbox>
          <Checkbox
            isChecked={acceptsTermsOfUse}
            onChange={(e) => setAcceptsTermsOfUse(e.target.checked)}
          >
            I accept the{' '}
            <Link
              variant='photonLavender'
              isExternal
              href={`${config.appBaseUrl}/terms-and-conditions`}
            >
              Terms & Conditions
            </Link>
            <sup>*</sup>
          </Checkbox>
        </Flex>

        <Flex mt='20px' justifyContent='space-around' w='50%' alignSelf='center'>
          <Button variant='photonMedium' mr={3} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            variant='photonDark'
            onClick={handleSave}
            isDisabled={!acceptsTermsOfUse || !privacyNotice}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
