import React from 'react';
import {createRoot} from 'react-dom/client';

import {RouterProvider} from 'react-router-dom';

import {ChakraProvider} from '@chakra-ui/react';

import './assets/css/App.css';
import theme from './theme/theme';
import {router} from './App';
import {AuthProvider} from './hooks/useAuth';
import {ErrorBoundary} from './util/bugsnag-initer';
import {initMocking} from './util/mocks/msw-initer';


initMocking().then(
  () => {
    const root = createRoot(document.getElementById('root'));
    root.render(
      <ErrorBoundary>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <AuthProvider>
              <RouterProvider router={router}/>
            </AuthProvider>
          </React.StrictMode>
        </ChakraProvider>
      </ErrorBoundary>,
    );
  },
);
