import * as React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import {useEffect} from 'react';


const RoutingUtil: React.FC = () => {
  const location = useLocation();
  const [, setSearchText] = useLocalStorage('projectSearch');

  useEffect(
    () => {
      if (!location.pathname?.includes('intel/project')) {
        setSearchText('');
      }
    },
    [location],
  );

  return <Outlet />;
};

export default RoutingUtil;
