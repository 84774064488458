
export const SOCKET_URL = import.meta.env.VITE_API_SOCKET_URL || 'https://api.thephoton.hu';
export const PROD_ENV = 'production';
export const DEV_ENV = 'development';
export const DEV_OFFLINE_ENV = 'development_offline';


export default {
  environment: import.meta.env.VITE_ENV || PROD_ENV,
  apiUrl: import.meta.env.VITE_API_URL || 'https://api.thephoton.hu/api',
  SOCKET_URL,
  reCaptchaKey: import.meta.env.VITE_CAPTCHA_KEY || '6Ld9kb4eAAAAAPz2lWRJTnas3ORa3mKSCut7e38E',
  appBaseUrl: import.meta.env.VITE_BASE_URL || 'https://photon.rocks',
};
