export const datePickerStyles = {
  baseStyle: {
    'mb': '0.5rem',
    'w': '300px',
    'h': '50px',
    'bg': 'white',
    'borderRadius': '15px',
    'pl': '20px',
    'pt': '12px',
    'fontSize': '16px',
    'border': 'solid 1px #E0E5F2',
    '.react-datetime-picker__wrapper': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      borderRadius: '30px',
    },
    '.react-calendar': {
      bg: 'white',
      borderRadius: '10px',
      border: 'none',
      height: '320px',
    },
    '.react-calendar__navigation': {
      w: '100%',
      display: 'flex',
      h: '50px',
      mb: '0',
      bg: '#a6a4ff',
      fontSize: '20px',
    },
    '.react-calendar__navigation button': {
      bg: '#a6a4ff',
      color: 'black',
      borderRadius: '5px',
      border: 'none',
      p: '5px 10px',
      fontSize: '14px',
      cursor: 'pointer',
      h: '50px',
    },
    '.react-calendar__navigation button:hover': {
      bg: 'rgb(149, 148, 233) !important',
    },
    '.react-calendar__navigation button:focus': {
      bg: '#a6a4ff !important',
    },
    '.react-calendar__navigation__arrow': {
      w: '50px',
      h: '50px',
    },
    '.react-calendar__tile': {
      bg: 'white',
      color: '#333',
      borderRadius: '5px',
      p: '10px',
    },
    '.react-calendar__month-view__weekdays': {
      display: 'none',
      bg: '#a6a4ff',
    },
    '.react-calendar__month-view__days__day--weekend': {
      color: 'red',
    },

    '.react-calendar__tile--active': {
      bg: '#a6a4ff',
      color: 'white',
    },
  },
};
