import {makeUseAxios} from 'axios-hooks';
// import LruCache from 'lru-cache';
import serverClient from './serverClient';

export default makeUseAxios(
  {
    cache: false,
    // cache: new LruCache(
    //     {
    //         max: 50,
    //         ttl: 1000 * 10,
    //     }
    // ),
    axios: serverClient(),
  },
);
