import {MouseEventHandler, useCallback, useEffect, useState} from 'react';
import io from 'socket.io-client';

import Bugsnag from '@bugsnag/js';

import {Button, Flex, Input, InputGroup, InputLeftElement, Spinner, useToast} from '@chakra-ui/react';
import {FaSearch} from 'react-icons/fa';
import {TiDeleteOutline} from 'react-icons/ti';

import useLocalStorage from 'react-use-localstorage';

import {useGetOngoingProjects} from '../../../api/projects-api';
import ProjectTable from '../../../components/ProjectTable';
import {columnsDataOngoingProjects} from '../variables/columnsData';
import {SOCKET_URL} from '../../../config';
import {USER} from '../../../hooks/useLocalStorage';


const OngoingProjects = () => {
  const toast = useToast();
  const [searchField, setSearchField] = useLocalStorage('projectSearch');
  const [userCode] = useLocalStorage(USER);
  const [socket, setSocket] = useState(null);
  const [
    {
      data: projectsData,
      loading: getProjectsInProgress,
      error: getProjectsError,
    },
    getProjects,
  ] = useGetOngoingProjects();

  const reloadProjects = useCallback(
    async () => {
      try {
        await getProjects({
          params: {
            search: searchField,
          },
        });
      } catch (error) {
        console.error('Error during reload:', error);
      }
    },
    [getProjects, searchField],
  );

  useEffect(
    () => {
      socket?.off('projectAvailable');

      socket?.on(
        'projectAvailable',
        () => reloadProjects(),
      );
    },
    [socket],
  );

  useEffect(
    () => {
      const user = userCode ? JSON.parse(userCode) : null;

      const socket = io(
        SOCKET_URL,
        {auth: {token: user?.accessToken}},
      );

      setSocket(socket);

      return () => {
        socket?.disconnect();
        setSocket(null);
      };
    },
    [userCode],
  );

  const handleSearchClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    reloadProjects();
  };

  const handleResetInput: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    setSearchField('');
    reloadProjects();
  };

  useEffect(() => {
    if (getProjectsError) {
      Bugsnag.notify(getProjectsError);
      console.error(getProjectsError);
      toast({
        title: 'Server error',
        description: 'Error while fetching data',
        status: 'error',
        isClosable: true,
      });
    }
  }, [getProjectsError]);

  useEffect(() => {
    reloadProjects();
  }, []);

  return <>
    {/* loading data */}
    {getProjectsInProgress && <Spinner/>}

    {/* error during loading data */}
    {getProjectsError && <p>Error fetching data</p>}

    <Flex pos='absolute' right='50px' top={-5}>
      <Flex direction='column' mb={4} mr='10px'>
        <InputGroup>
          {searchField && <InputLeftElement>
            <Button size='20' onClick={handleResetInput}>
              <TiDeleteOutline size='20'/>
            </Button>
          </InputLeftElement>}
          <Input
            variant='tableSearch'
            placeholder='Search by project name'
            onChange={
              (e) => setSearchField(e.target?.value)
            }
            value={searchField}
            w='250px'
          />
        </InputGroup>
      </Flex>
      <Button onClick={handleSearchClick} bg='white' borderRadius='20px'>
        <FaSearch color='black'/>
      </Button>
    </Flex>

    {/* get result */}
    {
      !getProjectsInProgress
      && projectsData
      && projectsData.length > 0
      && <ProjectTable
        columnsData={columnsDataOngoingProjects}
        tableData={projectsData}
        total={projectsData.length}
        reloadData={getProjects}
      />
    }

    {/* no data */}
    {
      !getProjectsInProgress
      && projectsData
      && projectsData.length === 0
      && <p>No data</p>
    }
  </>;
};

export default OngoingProjects;
